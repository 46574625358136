html,
body,
#root {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cesium-viewer, #map {
    position: absolute;
    padding: 0;
    margin: 0;
    width: 60%;
    height: 100%;
    left: 40%;
    top: 0;
}

.cesium-credit-textContainer {
    display: none !important;
}

#menu {
    position: absolute;
    width: 40%;
    height: 100%;
    top: 0;
    background-color: #333333;
    color: #ffffff;
}

@media only screen and (min-width: 800px)  {
    #menu {
        position: absolute;
        width: 40%;
        height: 100%;
        top: 0;
        background-color: #333333;
        color: #ffffff;
    }

    .cesium-viewer, #map {
        position: absolute;
        padding: 0;
        margin: 0;
        width: 60%;
        height: 100%;
        left: 40%;
        top: 0;
    }
}

@media only screen and (min-width: 1230px)  {
    #menu {
        position: absolute;
        width: 20%;
        height: 100%;
        top: 0;
        background-color: #333333;
        color: #ffffff;
    }

    .cesium-viewer, #map {
        position: absolute;
        padding: 0;
        margin: 0;
        width: 80%;
        height: 100%;
        left: 20%;
        top: 0;
    }
}

.marker {
    color: #ff0000;
    font-size: 2vh;
}

#a .cesium-viewer {
    width: 100% !important;
    left: 0 !important;
}